<div class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">APP</span>
                    <h2>Control de asistencia de personal fácil y seguro</h2>
                    <p>Descubre la manera más eficiente de <b>gestionar el control horario</b> de tu empresa con nuestra aplicación móvil.
                        Diseñada tanto para celulares como para tablets, nuestra app te permite marcar entradas y salidas con
                        geolocalización y foto, asegurando un seguimiento preciso y confiable de la <b>asistencia de los trabajadores</b>.</p>
                    <div class="features-text">
                        <h3>Precisión y confiabilidad en cada marca</h3>
                        <p>Nuestra aplicación se destaca por su confiabilidad y precisión. En un simple paso, los empleados pueden marcar su
                            entrada y salida, registrando automáticamente la hora y ubicación exacta, acompañado de una foto para mayor
                            seguridad. Esta herramienta es ideal para empresas que buscan <b>optimizar la gestión del tiempo</b> y cumplir con las
                            regulaciones laborales de manera sencilla y eficaz.</p>
                    </div>
                    <div class="features-text">
                        <h3>Flexibilidad en el control de asistencias desde cualquier ubicación</h3>
                        <p>Con nuestro software, el <b>control de asistencia de personal</b> es más flexible que nunca. Tus empleados pueden marcar su entrada y
                            salida desde cualquier lugar, gracias a la geolocalización. Esta funcionalidad es perfecta para equipos que trabajan
                            de manera remota, en campo o que se desplazan constantemente, permitiendo una gestión eficiente del tiempo laboral
                            sin importar dónde se encuentren. La combinación de facilidad de uso, precisión de datos y adaptabilidad a distintos
                            dispositivos convierte nuestro software en la solución ideal para empresas modernas que valoran la movilidad y la
                            eficiencia.</p>
                    </div>
                    <!-- <div class="btn-box">
                        <a routerLink="/sign-up" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-1" class="link-btn">See All Features</a>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/app_cucu_v2.webp" data-aos="fade-up" alt="Aplicación móvil de control de asistencia de personal" width="605" height="786">
                </div>
            </div>
        </div>
    </div>
</div>