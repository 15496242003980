<div class="container">
    <div class="section-title">
        <span class="sub-title">¿POR QUÉ CUCÚ?</span>
        <h2>¿Estás listo para transformar el control de asistencia de personal de tu empresa?</h2>
    </div>
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
            <div class="single-features-item without-hover">
                <div class="icon">
                    <i class="ri-smartphone-line"></i>
                </div>
                <h3>Multidispositivo</h3>
                <p>Las personas podrán marcar la asistencia desde cualquier dispositivo: móviles, tablets o relojes biométricos.</p>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
            <div class="single-features-item without-hover">
                <div class="icon bg2">
                    <i class="ri-pie-chart-2-line"></i>
                </div>
                <h3>Análisis en dashboard</h3>
                <p>Visualiza datos clave en tiempo real con nuestro potente dashboard intuitivo y completo.</p>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
            <div class="single-features-item without-hover">
                <div class="icon bg3">
                    <i class="ri-draft-line"></i>
                </div>
                <h3>Reportes Detallados</h3>
                <p>Obtén informes precisos y personalizados para un seguimiento eficiente de la asistencia.</p>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
            <div class="single-features-item without-hover">
                <div class="icon bg4">
                    <i class="ri-secure-payment-line"></i>
                </div>
                <h3>Soporte Garantizado</h3>
                <p>Asistencia técnica experta siempre disponible para garantizar un funcionamiento óptimo.</p>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
            <div class="single-features-item without-hover">
                <div class="icon bg5">
                    <i class="ri-cloud-line"></i>
                </div>
                <h3>Actualizaciones</h3>
                <p>Disfruta de las últimas funcionalidades sin costos adicionales. Siempre al día.</p>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
            <div class="single-features-item without-hover">
                <div class="icon bg6">
                    <i class="ri-coins-line"></i>
                </div>
                <h3>Los precios más bajos</h3>
                <p>Disfruta de los precios más bajos del mercado por usuario, maximizando tu inversión.</p>
            </div>
        </div>
        <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
            <div class="text-center">
                <a routerLink="/sign-in" class="default-btn">Comenzar</a>
            </div>
        </div> -->
    </div>
</div>