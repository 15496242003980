<div class="banner-area">
    <div class="container-fluid">
        <div class="row align-items-top m-0">
            <div class="col-lg-6 col-md-12 p-3">
                <div class="banner-content">
                    <span class="sub-title">Prueba gratuita por 30 días</span>
                    <h1>Software de control de asistencia de personal en la nube</h1>
                    <p>Optimice la gestión de su equipo con nuestro <strong>software de
                        control de asistencia</strong>. Automatice registros, mejore la puntualidad y obtenga
                        informes detallados en tiempo real. Ideal para <strong>empresas que buscan eficiencia</strong> y
                        control absoluto en la gestión de su personal.</p>
                    <a routerLink="/como-funciona" class="default-btn">¿Cómo funciona?</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feature-cards">
                    <div class="feature-card">
                        <div class="feature-image">
                            <img src="assets/img/banner/Obrero.webp" alt="Trabajador registrando su asistencia" width="1024" height="1024">
                        </div>
                        <h3 class="feature-title">REGISTRA TU ASISTENCIA</h3>
                        <p class="feature-description">
                            Tus empleados pueden marcar su <strong>entrada y salida</strong> desde cualquier lugar, gracias a la
                            <strong>geolocalización</strong>. Esta información, junto con una foto para <strong>reconocimiento facial</strong>, es enviada
                            automáticamente al software de control de asistencia.
                        </p>
                    </div>
                    <div class="feature-card">
                        <div class="feature-image">
                            <img src="assets/img/banner/Administrativo.webp" alt="Monitoreo en tiempo real en el software" width="1024" height="1024">
                        </div>
                        <h3 class="feature-title">MONITOREO EN TIEMPO REAL</h3>
                        <p class="feature-description">
                            En el <strong>software de fichaje</strong> se pueden ver en tiempo real todas las asistencias realizadas,
                            incluyendo la localización exacta de cada fichaje, facilitando la gestión y <strong>control del
                            personal</strong>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>