<app-navbar-style-two id="top"></app-navbar-style-two>

<!-- <app-homefour-banner></app-homefour-banner> -->
 <app-hometwo-banner></app-hometwo-banner>
 
<div class="partner-area ptb-100" id="video">
    <!-- <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div> -->
</div>
<div>
    <app-homefour-video></app-homefour-video>
</div>

<div class="margin-top-5" id="features">
    <app-homefour-features></app-homefour-features>
</div>

<div class="features-area pt-100">
    <app-features-style-three></app-features-style-three>
</div>

<div class="feedback-wrap-area pt-100 pb-25">
    <app-feedback-style-three></app-feedback-style-three>
</div>

<div class="app-download-area pt-100 pb-25">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<div id="faq"></div>
<div class="feedback-wrap-area pt-100">
    <app-faq-page></app-faq-page>
</div>

<div id="pricing"></div>
<div class="pricing-area pb-75 margin-top-10">
    <app-pricing-style-two></app-pricing-style-two>
</div>

<app-footer-style-one></app-footer-style-one>