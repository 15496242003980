<div class="container">
    <div class="section-title">
        <h2>Sección de Preguntas Frecuentes (FAQ)</h2>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="faq-accordion accordion">
                <div class="accordion">
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(0)"
                            [ngClass]="{ 'open': isSectionOpen(0) }">
                            ¿Qué es un software de control de asistencia y cómo funciona?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                            <p>Un <strong>software de control de asistencia</strong> es una herramienta que permite registrar y gestionar la asistencia de los empleados de manera digital. <strong>Cucutime</strong> funciona en la nube 
                                y permite a las empresas llevar un control horario eficiente mediante <strong>marcaciones desde dispositivos móviles, tablets o computadoras</strong>, con funcionalidades 
                                avanzadas como <strong>geolocalización, reconocimiento facial y reportes automáticos.</strong></p>
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(1)"
                            [ngClass]="{ 'open': isSectionOpen(1) }">
                            ¿Para qué empresas es ideal Cucutime?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                            <p>Cucutime es ideal para <strong>empresas de todos los tamaños</strong> que buscan digitalizar su control de asistencia. Se adapta perfectamente a sectores como <strong>retail, 
                                construcción, logística, educación, salud, seguridad, industrias y más.</strong> Nuestra solución permite <strong>gestionar turnos, reducir ausentismo y generar reportes en tiempo real.</strong></p>
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(2)"
                            [ngClass]="{ 'open': isSectionOpen(2) }">
                            ¿Cucutime permite registrar asistencia desde el celular?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                            <p>Sí. Con <strong>Cucutime</strong>, los empleados pueden marcar asistencia desde su <strong>smartphone, tablet o computadora.</strong> Además, el sistema permite configurar <strong>geolocalización y biometría facial</strong>, 
                                garantizando que las marcaciones sean <strong>seguras y verificadas.</strong>
                            </p>
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(3)"
                            [ngClass]="{ 'open': isSectionOpen(3) }">
                            ¿Cómo evita Cucutime fraudes en el control de asistencia?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                            <p>Cucutime tiene varias medidas antifraude, como:</p>
                            <ul>
                                <li><strong>Geolocalización</strong>: Permite registrar la ubicación de cada marcación.</li>
                                <li><strong>Reconocimiento facial</strong>: Verifica la identidad del empleado en cada marca.</li>
                                <li><strong>Restricción de IP y dispositivos</strong>: Solo permite registros desde ubicaciones y dispositivos autorizados.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(4)"
                            [ngClass]="{ 'open': isSectionOpen(4) }">
                            ¿Es posible gestionar turnos y horarios rotativos?

                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                            <p>Sí. Cucutime permite <strong>crear y administrar turnos fijos, rotativos o personalizados</strong>, adaptándose a la jornada laboral de cada empresa. 
                            </p>
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(5)"
                            [ngClass]="{ 'open': isSectionOpen(5) }">
                            ¿Puedo obtener reportes de asistencia y horas trabajadas en Excel o PDF?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                            <p>Sí, Cucutime genera informes detallados de asistencia, horas trabajadas, horas extras y ausencias en formatos <strong>Excel y PDF</strong>, 
                                facilitando la gestión de recursos humanos y el cálculo de nóminas.</p>                           
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(6)"
                            [ngClass]="{ 'open': isSectionOpen(6) }">
                            ¿Cucutime envía notificaciones a empleados y supervisores?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                            <p>Sí. El sistema envía alertas automáticas cuando un empleado: </p>
                            <ul>
                                <li>Llega tarde o se ausenta.</li>
                                <li>Marca asistencia fuera de la zona autorizada.</li>
                                <li>Tiene inconsistencias en sus registros.</li>
                            </ul>
                            <p>Los supervisores reciben estas alertas en la plataforma y vía <strong>Telegram o correo electrónico.</strong></p>
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(7)"
                            [ngClass]="{ 'open': isSectionOpen(7) }">
                            ¿Cómo protege Cucutime la privacidad de los empleados?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(7) }">
                            <p>Cucutime <strong>cifra todos los datos</strong> para garantizar la seguridad de la información. Además, cumple con normativas de protección de datos y 
                                permite configurar <strong>niveles de acceso</strong> para que solo usuarios autorizados accedan a la información.</p>
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(9)"
                            [ngClass]="{ 'open': isSectionOpen(9) }">
                            ¿Cumple Cucutime con normativas laborales y legales?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(9) }">
                            <p>Sí. Cucutime se adapta a <strong>diferentes legislaciones laborales</strong>, permitiendo registrar la jornada laboral de acuerdo con 
                                los requisitos legales de cada país, facilitando auditorías y evitando sanciones.</p>            
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(10)"
                            [ngClass]="{ 'open': isSectionOpen(10) }">
                            ¿Cucutime necesita instalación o es 100% en la nube?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(10) }">
                            <p>Cucutime es una solución en la <strong>nube</strong>, por lo que no <strong>requiere instalación</strong>. Se puede acceder desde cualquier dispositivo con conexión a Internet, 
                                lo que facilita la gestión remota del personal.</p>            
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(11)"
                            [ngClass]="{ 'open': isSectionOpen(11) }">
                            ¿Se puede integrar Cucutime con otros sistemas de nómina o RRHH?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(11) }">
                            <p>Sí. Cucutime ofrece integraciones con <strong>softwares de nómina, recursos humanos y contabilidad</strong>, facilitando el procesamiento de pagos y la gestión de personal.</p>            
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(12)"
                            [ngClass]="{ 'open': isSectionOpen(12) }">
                            ¿Funciona Cucutime sin conexión a internet?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(12) }">
                            <p>Sí. En caso de que un empleado no tenga conexión, puede registrar su asistencia de forma <strong>offline</strong>, y el sistema sincronizará 
                                los datos automáticamente cuando se restablezca la conexión.</p>            
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(13)"
                            [ngClass]="{ 'open': isSectionOpen(13) }">
                            ¿Cuánto cuesta Cucutime?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(13) }">
                            <p>Ofrecemos diferentes planes según el tamaño de tu empresa y tus necesidades. Puedes consultar los precios y elegir el que mejor 
                                se adapte a ti en nuestra sección de Planes y Precios.</p>            
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(14)"
                            [ngClass]="{ 'open': isSectionOpen(14) }">
                            ¿Ofrecen un período de prueba gratuito?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(14) }">
                            <p>Sí. <strong>Cucutime ofrece un mes de prueba gratuito</strong> para que puedas evaluar el sistema antes de contratarlo.</p>            
                        </div>
                    </div>
                    <div class="accordion-section">
                        <div class="accordion-header" (click)="toggleSection(15)"
                            [ngClass]="{ 'open': isSectionOpen(15) }">
                            ¿Cómo se paga Cucutime y qué métodos de pago aceptan?
                        </div>
                        <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(15) }">
                            <p>El pago de Cucutime es mensual o anual. Aceptamos pagos con <strong>tarjeta de crédito, transferencia bancaria y otros métodos seguros.</strong></p>            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>